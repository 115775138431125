<template>
  <!-- Card -->
  <div class="col-12 col-md-4 col-lg-3 mb-3">
    <div
      class="card border-radius-9 overflow-hidden border-0 h-100 hover-shadow"
      v-bind:class="card.cardClass"
    >
      <!-- BG Image -->
      <div
        class="card-img-overlay mb-n4 mr-n4 d-flex"
        style="bottom: 0; top: auto"
      >
        <img
          v-bind:src="card.bgImage"
          alt=""
          class="ml-auto d-block"
          v-bind:class="card.imageMargin"
          style="max-height: 105px"
        />
      </div>
      <!-- ./BG Image -->

      <div class="card-body">
        <!-- Top Buttons -->
        <div
          class="text-right position-absolute"
          style="top: 17px; right: 17px; z-index: 1"
        >
          <router-link
            v-if="card.routerLink"
            v-bind:to="card.routerLink"
            class="btn btn-outline-light btn-sm mr-2 border-0"
            ><i class="fas fa-eye"></i
          ></router-link>
          <span
            class="btn btn-outline-light btn-sm border-0"
            v-on:click="onRefresh"
            ><i class="fas fa-sync"></i
          ></span>
        </div>
        <template v-if="card.loading && showLoader">
          <div style="min-height: 151px">
            <WeLoading />
          </div>
        </template>

        <template v-else>
          <!-- Title -->
          <h5 class="text-transparent-white-8 mb-2">
            {{ card.title }}
          </h5>
          <!-- ./Title -->

          <div class="row align-items-center mb-2">
            <div class="col">
              <!-- Value -->
              <h3 class="mb-0 text-white">{{ card.textValue }}</h3>
              <!-- ./Value -->
            </div>
            <div
              class="col-auto"
              v-if="
                card.name == 'marketplaceOrders' ||
                card.name == 'marketplaceReturns' ||
                card.name == 'newMembers'
              "
            >
              <span class="text-transparent-white-7">
                {{ card.price | moneyFormat }}
              </span>
            </div>
          </div>

          <!-- Progress Bar -->
          <div class="d-block">
            <div class="progress bg-black-transparent-3">
              <div
                class="progrss-bar progress-bar-striped bg-white"
                role="progressbar"
                v-bind:style="{ width: `${card.percent}%` }"
                v-bind:aria-valuenow="card.percent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <!-- ./Progress Bar -->
          <!-- Rate -->
          <div class="text-transparent-white-7 my-3 font-13">
            <template v-if="showPercent">
              {{ diffPeriodText }}
              <b class="ml-1">
                <i v-bind:class="getPercentArrow"></i> % {{ getPercent }}</b
              >
            </template>
            <i class="fas fa-minus" v-else></i>
          </div>
          <!-- ./Rate -->

          <!-- Report Detail -->
          <div class="d-flex align-items-center position-relative">
            <span class="text-white">
              <i
                class="fas fa-calendar-alt fa-sm mr-2"
                v-tooltip.top="'Güncellenme Tarihi'"
              ></i>
              {{ updateDateTime | dateFormat }}
            </span>
          </div>
          <!-- ./Report Detail -->
        </template>
      </div>
    </div>
  </div>
  <!-- ./Card -->
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "WeStatsCard",
  data() {
    return {
      updateDateTime: null,
      timer: null,
      showLoader: false,
    };
  },
  props: {
    cards: {
      default: () => [],
    },
    cardIndex: {
      default: 0,
    },
  },
  methods: {
    onRefresh(showLoader = false) {
      const data = {};
      data.name = this.card.name;
      data.index = this.cardIndex;

      this.$emit("on-refresh", data);
      this.setUpdateDateTime();
      this.showLoader = showLoader;
      this.cards.sort();
    },
    setUpdateDateTime() {
      this.updateDateTime = new Date(Date.now());
    },
    updateTimer() {
      this.timer = new Date(Date.now());
    },
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
    card() {
      return this.cards[this.cardIndex];
    },
    getPercent() {
      return Math.abs(this.card.percent);
    },
    getPercentArrow() {
      let result = "fas fa-caret-";

      if (this.card.percent < 0) {
        result += "down";
      } else if (this.card.percent > 0) {
        result += "up";
      } else {
        result = "";
      }

      return result;
    },
    diffPeriodText() {
      let result = "";
      switch (this.report.period) {
        case "today":
          result = "Düne";
          break;

        case "yesterday":
          result = "Önceki Güne";
          break;

        case "month":
          result = "Geçen Aya";
          break;

        case "six_month":
          result = "Geçen 6 Aya";
          break;

        case "week":
        default:
          result = "Geçen Haftaya";
          break;
      }

      return result + " göre";
    },
    showPercent() {
      return this.report.period !== "lifetime" && !this.report.periodDate;
    },
  },
  mounted() {
    this.onRefresh(true);
  },
  watch: {
    "report.period": function (newVal) {
      if (newVal && newVal !== "custom_date") {
        this.onRefresh(true);
      }
    },
    "report.periodDate": function (newVal) {
      if (newVal && newVal.start && newVal.end) {
        this.onRefresh(true);
      }
    },
    timer: function (newVal) {
      let difference = helper.getTimerDiff(this.timer, this.updateDateTime);

      if (difference >= 3) {
        this.onRefresh();
      }
    },
  },
  created() {
    setInterval(this.updateTimer, 1000);
  },
};
</script>
